import { baseAxios } from "../index";

export async function tambahClient(data) {
  try {
    const response = await baseAxios.post("webClient/tambahClient", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function getAllClient(dispatch) {
  try {
    const response = await baseAxios.get("webClient/getAllClient", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALLCLIENT", data: response.data });
    return response.status;
  } catch (err) {
    return err.response;
  }
}
