import { baseAxios } from "../index";

export async function tambahMarketing(data) {
  try {
    const response = await baseAxios.post(
      "webMarketing/tambahMarketing",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function getAllMarketing(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `webMarketing/getAllMarketing?idClient=${data.idClient}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALLMARKETING", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function penempatanMarketing(data) {
  try {
    const response = await baseAxios.post(
      "webMarketing/penempatanMarketing",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function tambahTargetMarketing(data) {
  try {
    const response = await baseAxios.post(
      "webMarketing/tambahTargetMarketing",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function getAllTargetMarketing(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `webMarketing//getAllTargetMarketing?idClient=${data.idClient}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_TARGET_MARKETING", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function editTargetMarketing(data) {
  try {
    const response = await baseAxios.patch(
      "webMarketing/ubahTargetMarketing",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function editStatusMarketing(data) {
  try {
    const response = await baseAxios.patch(
      "webMarketing/ubahStatusMarketing",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    return response;
  } catch (err) {
    throw err.response.data;
  }
}
