import { baseAxios } from "../index";
import Swal from "sweetalert2";
import moment from "moment";

export async function getAllTotalSupervisor(dispatch) {
  try {
    const response = await baseAxios.get("webDashboard/getTotalSupervisor", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALL_TOTAL_SUPERVISOR", data: response.data });
    // console.log(response, "???????????");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllTotalAreaManager(dispatch) {
  try {
    const response = await baseAxios.get("webDashboard/getTotalAreaManager", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALL_TOTAL_AREAMANAGER", data: response.data });
    // console.log(response, "???????????");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllTotalMarketing(dispatch) {
  try {
    const response = await baseAxios.get("webDashboard/getTotalMarketing", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALL_TOTAL_MARKETING", data: response.data });
    // console.log(response, "???????????");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllTotalCabang(dispatch) {
  try {
    const response = await baseAxios.get("webDashboard/getTotalCabang", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALL_TOTAL_CABANG", data: response.data });
    // console.log(response, "???????????");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAllTotalPengajuan(dispatch, data, history) {
  try {
    const response = await baseAxios.post(
      "webPengajuan/getAllTotalPengajuan",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_TOTAL_PENGAJUAN", data: response.data.data });
    // console.log(response, "KKK");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getGrafikPencairanCabang(dispatch, data, history) {
  try {
    const response = await baseAxios.post(
      "webDashboard/getGrafikPencairanCabang",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    // dispatch({ type: "SET_ALL_PENCAIRAN", data: response.data });
    // console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getGrafikPencairanUser(dispatch, data, history) {
  try {
    const response = await baseAxios.post(
      "webDashboard/getGrafikPencairanPerBulanByLogin",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    // dispatch({ type: "SET_ALL_PENCAIRAN", data: response.data });
    // console.log(response);
    // console.log(response, "PPPPPPPP");
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getExportPengajuan(data) {
  try {
    const response = await baseAxios.post(
      "webPengajuan/exportSementara",
      data,
      {
        headers: { token: localStorage.getItem("token") },
        responseType: "blob",
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    return err.response;
  }
}
