import React from "react";
import {
  Button,
  Card,
  Form,
  Col,
  Modal,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Label } from "reactstrap";
import { Document, Page, pdfjs } from "react-pdf";
// import "react-pdf/dist/esm/Page/AnnotationLayer.css";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Step6() {
  const dispatch = useDispatch();
  const pengajuan = useSelector((state) => state.pengajuanReducer);
  const valDetail = !pengajuan.detailPengajuan.stepEnam
    ? []
    : pengajuan.detailPengajuan.stepEnam;
  const [listUrlDokumen, setListUrlDokumen] = React.useState([]);

  const handleClickPdf = (url) => {
    dispatch({ type: "PREVIEW_DOKUMEN", data: url });
  };

  React.useEffect(() => {
    let tmp = [];
    Object.entries(valDetail).forEach(([key, value]) => {
      if (
        key != "_id" &&
        key != "id_pinjaman" &&
        key != "createdAt" &&
        key != "updatedAt"
      ) {
        tmp.push({
          key: key,
          url: value,
        });
      }
    });
    setListUrlDokumen(tmp);
  }, []);

  return (
    <Col md="12" style={{ marginTop: 20 }}>
      <Card.Header>
        <Card.Title
          as="h4"
          style={{
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          Dokumen
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Card style={{ border: 0 }}>
          <div style={{ maxHeight: "450px", overflowY: "scroll" }}>
            <div className="row">
              <div className="col-12">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  PDF
                </Label>
                {listUrlDokumen.map((val) => {
                  return (
                    <>
                      {/* <li> */}
                      <Row>
                        <Col md={"4"}>
                          <Label
                            style={{
                              textTransform: "uppercase",
                              fontSize: 15,
                              fontWeight: "bold",
                              color: "#000000",
                            }}
                          >
                            <li>{val.key}</li>
                          </Label>
                        </Col>
                        <Col sm={"2"}>
                          <Button
                            className="mr-0"
                            onClick={() => {
                              handleClickPdf(val.url);
                            }}
                            style={{
                              marginBottom: 10,
                              fontSize: 16,
                              backgroundColor: "#E8EEF1",
                              border: 0,
                              paddingLeft: 10,
                              paddingRight: 10,
                              color: "#496679",
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "bold",
                            }}
                          >
                            Lihat
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={"0"}>
                          <div>
                            <i
                              class="far fa-file-pdf"
                              style={{
                                fontSize: 40,
                                marginBottom: 10,
                                marginLeft: 20,
                              }}
                            ></i>
                          </div>
                        </Col>
                        <Col>
                          <div
                            style={{
                              color: "blue",
                              marginLeft: 15,
                              marginTop: 10,
                            }}
                          >
                            {val.url}
                          </div>
                        </Col>
                      </Row>
                      {/* </li> */}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </Card>
      </Card.Body>
    </Col>
  );
}
export default Step6;
