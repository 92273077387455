const initialState = {
  listSupervisor: [],
  statusInputTargetSpv: true,
  listTargetSupervisor: [],
};

const supervisorReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALLSUPERVISOR":
      return {
        ...state,
        listSupervisor: data,
      };
    case "SET_STATUS_INPUT_TARGET_SUPERVISOR":
      return {
        ...state,
        statusInputTargetSpv: data,
      };
    case "SET_ALL_TARGET_SUPERVISOR":
      return {
        ...state,
        listTargetSupervisor: data,
      };
    default:
      return state;
  }
};
export default supervisorReducer;
