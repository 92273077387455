import React from "react";
import ChartistGraph from "react-chartist";
import DataTable from "react-data-table-component";
import Select from "react-select";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  Image,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Label } from "reactstrap";
import { getAllTotalAreaManager } from "stores";
import { getAllTotalPengajuan } from "stores";
import ReactDatetime from "react-datetime";
import { getTotalPencairan } from "stores/Pengajuan/function";
import { getGrafikPencairanCabang } from "stores";
import moment from "moment";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import { getTotalTargetUser } from "stores/Pengajuan/function";
import { getGrafikPencairanUser } from "stores";
import { getAllTotalSupervisor } from "stores";
import { getAllTotalMarketing } from "stores";
import { getAllTotalCabang } from "stores";
import { getExportPengajuan } from "stores";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function DashboardUtama() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const cardTotalAreaManager = useSelector(
    (state) => state.dashboardUtamaReducer
  );
  // const cardTotalPencairan = useSelector((state) => state.pengajuanReducer);
  const cardTotalPengajuan = useSelector(
    (state) => state.dashboardUtamaReducer
  );
  // console.log(cardTotalPengajuan, "Dari Store");
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10;
  const totalTarget = hasilPresentase > 100;
  const [listAllTotalPengajuan, setListAllTotalPengajuan] = React.useState([]);
  const [totalNominalPencairan, setTotalNominalPencairan] = React.useState(0);
  const [totalNominalPencairanKonsumtif, setTotalNominalPencairanKonsumtif] =
    React.useState(0);
  const [totalNominalPencairanProduktif, setTotalNominalPencairanProduktif] =
    React.useState(0);
  const [hasilPresentase, setHasilPresentase] = React.useState(0);
  const [totalNominalTarget, setTotalNominalTarget] = React.useState(0);
  const [dataGrafikCabang, setDataGrafikCabang] = React.useState([]);
  const [dataGrafikUser, setDataGrafikUser] = React.useState([]);
  const [totalSpv, setTotalSpv] = React.useState([]);
  const [totalCbg, setTotalCbg] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [loadingPresentase, setLoadingPresentase] = React.useState(false);
  const [totalAM, setTotalAM] = React.useState([]);
  const [totalMarketing, setTotalMarketing] = React.useState([]);
  const [dataAllGrafikCabang, setDataAllGrafikCabang] = React.useState([]);
  const [dariTgl, setDariTgl] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
      0,
      0,
      0,
      0
    )
  );
  const [tglAwal, setTglAwal] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(
      0,
      0,
      0,
      0
    )
  );
  const [tglAkhir, setTglAkhir] = React.useState(new Date());
  const [smpTgl, setSmpTgl] = React.useState(new Date());
  const pencairanData = dataGrafikCabang.map((item) => item.totalPencairan);
  const pencairanDataKonsumtif = dataGrafikCabang.map(
    (item) => item.totalNominalKonsumtif
  );
  const pencairanDataProduktif = dataGrafikCabang.map(
    (item) => item.totalNominalProduktif
  );

  const targetData = dataGrafikCabang.map((item) => item.totalTarget);
  const [tahun, setTahun] = React.useState(new Date().getFullYear());
  let yearNow = new Date().getFullYear();
  const [listYear, setListYear] = React.useState([]);
  const bulanData = dataGrafikUser.map((item) => item.bulan);
  const pencairanDataUser = dataGrafikUser.map(
    (item) => item.totalNominalPencairan
  );
  const targetDataUser = dataGrafikUser.map((item) => item.totalNominalTarget);
  const pencairanDataUserKonsumtif = dataGrafikUser.map(
    (item) => item.totalNominalKonsumtif
  );
  const pencairanDataUserProduktif = dataGrafikUser.map(
    (item) => item.totalNominalProduktif
  );
  const abjadCabang = dataGrafikCabang.map((_, index) =>
    String.fromCharCode(65 + index)
  );
  //Modal
  const [modalDetail, setModalDetail] = React.useState(false);
  const [modalExport, setModalExport] = React.useState(false);

  const formatRupiah = (angka) => {
    const numberFormat = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    });
    return numberFormat.format(angka);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const closeModal = () => {
    setCurrentPage(1);
    setModalDetail(false);
  };

  const chartData = {
    labels: abjadCabang,
    series: [
      pencairanData,
      targetData,
      pencairanDataKonsumtif,
      pencairanDataProduktif,
    ],
  };

  const chartDataUser = {
    labels: bulanData,
    series: [
      targetDataUser,
      pencairanDataUser,
      pencairanDataUserKonsumtif,
      pencairanDataUserProduktif,
    ],
  };

  React.useEffect(() => {
    // setIsVisible(true);s
    getListYear();
  }, []);

  const submitExport = async (e) => {
    e.preventDefault();
    if (tglAwal === "" || tglAkhir === "" || status === "") {
      Swal.fire({
        title: "Semua Field Wajib Diisi",
        icon: "warning",
      });
      return;
    }

    Swal.fire({
      title: "Konfirmasi Export Pengajuan",
      text: `Apakah Anda ingin mengexport dari ${moment(tglAwal).format(
        "DD MMMM YYYY"
      )} sampai ${moment(tglAkhir).format("DD MMMM YYYY")} ?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya, Export",
      cancelButtonText: "Batal",
      allowOutsideClick: () => !Swal.isLoading(),
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          // Meminta server untuk ekspor data
          const response = await getExportPengajuan({
            tglAwal: moment(tglAwal).format("YYYY-MM-DD"),
            tglAkhir: moment(tglAkhir).format("YYYY-MM-DD"),
            status: status.value,
          });

          // Mengaktifkan unduhan setelah menerima respons dari server
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Export Pengajuan ${status.value}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);

          // Tampilkan swal.fire setelah unduhan selesai
          Swal.fire({
            icon: "success",
            title: "Data berhasil diexport",
          });
        } catch (error) {
          Swal.fire({
            title: "Terjadi kesalahan",
            text: error.message,
            icon: "error",
          });
        }
      },
    });
  };

  // const submitExport = (e) => {
  //   e.preventDefault();
  //   if (tglAwal === "" || tglAkhir === "" || status === "") {
  //     Swal.fire({
  //       title: "Semua Field Wajib Diisi",
  //       icon: "warning",
  //     });
  //     return;
  //   }
  //   getExportPengajuan({
  //     tglAwal: tglAwal,
  //     tglAkhir: tglAkhir,
  //     status: status.value,
  //   });
  // };

  const handleExportPengajuan = () => {
    setStatus("");
    // setTglAwal("");
    // setTglAkhir("");
  };

  React.useEffect(() => {
    let tmp =
      cardTotalPengajuan.totalPengajuan &&
      cardTotalPengajuan.totalPengajuan.map((val) => {
        return {
          ...val,
        };
      });
    setListAllTotalPengajuan(tmp || []);
  }, [cardTotalPengajuan.totalPengajuan]);

  React.useEffect(() => {
    getAllTotalPengajuan(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    });
  }, [dariTgl, smpTgl]);

  const getListYear = () => {
    let tmp = [];
    for (let i = 5; i >= 0; i--) {
      let years = yearNow--;
      tmp.push({
        value: years,
        label: years,
      });
    }
    setListYear(tmp);
  };

  React.useEffect(() => {
    setLoadingPresentase(true);
    getTotalPencairan(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    }).then((response) => {
      setLoadingPresentase(false);
      const totalPencairan = response.data.totalNominal;
      setTotalNominalPencairan(totalPencairan);
      const totalPencairanKonsumtif = response.data.totalNominalKonsumtif;
      setTotalNominalPencairanKonsumtif(totalPencairanKonsumtif);
      const totalPencairanProduktif = response.data.totalNominalProduktif;
      setTotalNominalPencairanProduktif(totalPencairanProduktif);
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    getTotalTargetUser(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    }).then((response) => {
      const totalTarget = response.data.totalTarget;
      setTotalNominalTarget(totalTarget);
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    getGrafikPencairanCabang(dispatch, {
      tglAwal: moment(dariTgl).format("YYYY-MM-DD"),
      tglAkhir: moment(smpTgl).format("YYYY-MM-DD"),
    }).then((response) => {
      const grafikCabang = response.data;
      let tmp = [];
      grafikCabang.map((val, index) => {
        if (index < 12) {
          tmp.push(val);
        }
      });
      setDataGrafikCabang(tmp);
      setDataAllGrafikCabang(grafikCabang);
    });
  }, [dariTgl, smpTgl]);

  React.useEffect(() => {
    getGrafikPencairanUser(dispatch, {
      tahun: tahun,
    }).then((response) => {
      const grafikUser = response.data;
      setDataGrafikUser(grafikUser);
    });
  }, [tahun]);

  React.useEffect(() => {
    if (totalNominalTarget !== 0 && totalNominalPencairan !== 0) {
      const presentase = Math.round(
        (totalNominalPencairan / totalNominalTarget) * 100
      );
      setHasilPresentase(presentase);
    } else {
      setHasilPresentase(0);
    }
  }, [totalNominalPencairan, totalNominalTarget]);

  const customStyles = {
    rows: {
      style: {
        height: 70, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        //fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colCabangPencairan = [
    {
      name: "No",
      width: "70px",
      center: true,
      selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
    },
    {
      name: "Nama Cabang",
      minWidth: "150px",
      center: true,
      selector: (row) => row.nama,
    },
    {
      name: "Total Pencairan",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = row.totalPencairan.toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        });
        return `${formattedValue}`;
      },
    },
    {
      name: "Total Target",
      minWidth: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = row.totalTarget.toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        });
        return `${formattedValue}`;
      },
    },
  ];

  React.useEffect(() => {
    getAllTotalSupervisor(dispatch).then((response) => {
      const dataSupervisor = response.data;
      setTotalSpv(dataSupervisor);
    });
  }, []);

  React.useEffect(() => {
    getAllTotalAreaManager(dispatch).then((response) => {
      const dataAreaManager = response.data;
      setTotalAM(dataAreaManager);
    });
  }, []);

  React.useEffect(() => {
    getAllTotalMarketing(dispatch).then((response) => {
      const dataMarketing = response.data;
      setTotalMarketing(dataMarketing);
    });
  }, []);

  React.useEffect(() => {
    getAllTotalCabang(dispatch).then((response) => {
      const dataCabang = response.data;
      setTotalCbg(dataCabang);
    });
  }, []);

  React.useEffect(() => {
    if (auth.role === "client") {
      const interval = setInterval(() => {
        window.location.reload();
        refresh();
      }, 300000);

      return () => clearInterval(interval);
    }
  }, [auth.role]);

  return (
    // <SkeletonTheme baseColor="#c713eb" highlightColor="#de84f0">
    <>
      <Modal
        size="lg"
        show={modalExport}
        onHide={() => {
          setModalExport(false);
          handleExportPengajuan();
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="12">
              <Form action="" className="form" method="">
                <Card style={{ border: 0 }}>
                  <Card.Title
                    as="h4"
                    style={{ fontWeight: "bold", textTransform: "none" }}
                  >
                    Export Pengajuan
                    <hr></hr>
                  </Card.Title>
                  <Card.Body>
                    <Row>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Tanggal Awal
                          </label>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                              readOnly: true,
                              style: {
                                cursor: "default",
                                color: "black",
                                backgroundColor: "white",
                              },
                            }}
                            onChange={(e) => {
                              e.toDate().setHours(0, 0, 0, 0),
                                setTglAwal(e.toDate().setHours(0, 0, 0, 0));
                            }}
                            value={tglAwal}
                            initialValue={tglAwal}
                            timeFormat={false}
                          ></ReactDatetime>
                          {/* <Form.Control
                            onChange={(e) => {
                              setTglAwal(e.target.value);
                            }}
                            type="date"
                          /> */}
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Tanggal Akhir
                          </label>
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: "Date Picker Here",
                              readOnly: true,
                              style: {
                                cursor: "default",
                                color: "black",
                                backgroundColor: "white",
                              },
                            }}
                            onChange={(e) => {
                              e.toDate().setHours(23, 59, 0, 0),
                                setTglAkhir(e.toDate().setHours(23, 59, 0, 0));
                            }}
                            value={tglAkhir}
                            initialValue={tglAkhir}
                            timeFormat={false}
                          ></ReactDatetime>
                          {/* <Form.Control
                            onChange={(e) => {
                              setTglAkhir(e.target.value);
                            }}
                            type="date"
                          /> */}
                        </Form.Group>
                      </Col>
                      <Col sm="12">
                        <Form.Group>
                          <label
                            style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "grey",
                              textTransform: "none",
                            }}
                          >
                            Status
                          </label>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleStatus"
                            onChange={(value) => setStatus(value)}
                            options={[
                              {
                                value: "",
                                label: "Pilih Status",
                                isDisabled: true,
                              },
                              {
                                value: "Semua",
                                label: "Semua Pengajuan",
                              },
                              {
                                value: "Disetujui",
                                label: "Disetujui",
                              },
                              {
                                value: "Diproses",
                                label: "Diproses",
                              },
                              {
                                value: "Menunggu Konfirmasi",
                                label: "Menunggu Konfirmasi",
                              },
                              {
                                value: "Revisi",
                                label: "Revisi",
                              },
                              {
                                value: "Ditolak",
                                label: "Ditolak",
                              },
                            ]}
                            placeholder="Pilih Status"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button
                      className="btn-fill pull-right"
                      type="submit"
                      // variant="info"
                      style={{
                        border: 0,
                        backgroundColor: "#77CA2A",
                        fontWeight: "bold",
                        fontSize: 15,
                      }}
                      onClick={submitExport}
                    >
                      Submit
                    </Button>
                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={modalDetail}
        onHide={closeModal}
        // onHide={() => setModalDetail(!modalDetail)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Details Data Cabang Pencairan
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body style={{ marginTop: 20 }}>
              <DataTable
                columns={colCabangPencairan}
                data={dataAllGrafikCabang}
                // .filter(
                //   (row) =>
                //     row.nama.toLowerCase().includes(searchText.toLowerCase()) ||
                //     row.nopeg.toLowerCase().includes(searchText.toLowerCase())
                // )}
                customStyles={customStyles}
                pagination
                paginationPerPage={itemsPerPage}
                onChangePage={handlePageChange}
              />
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Container fluid>
        <Row>
          <Col md="6" className="my-3">
            <Card
              style={{
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                borderRadius: 10,
                border: 0,
              }}
            >
              <Card.Header
                className="d-none d-md-inline"
                style={{
                  backgroundColor: "#3C4151",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}
              >
                <Card.Title as="h3" className="m-0">
                  <Row>
                    <Col
                      md="8"
                      className="p-3 d-flex align-items-center"
                      style={{
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      bpd kaltimtara
                    </Col>
                    <Col md="4" className="p-2 d-flex justify-content-end">
                      <Image
                        src={
                          "https://ptbap.net/assets/images/logo/invert-bap.png"
                        }
                        width="auto"
                        height="50px"
                        alt="Logo"
                      />
                    </Col>
                  </Row>
                </Card.Title>
              </Card.Header>
              <Row noGutters>
                <Col
                  lg="5"
                  className="d-none d-lg-flex align-items-center justify-content-center"
                >
                  <Image
                    src={require("assets/img/bankaltimtara.jpeg").default}
                    width="auto"
                    height="150px"
                    alt="Bank Altimtara"
                  />
                </Col>
                <Col lg="7" xs="12" className="p-3">
                  <Row className="numbers d-flex justify-content-around text-center">
                    <Col className="d-flex flex-column align-items-center mb-3">
                      <p className="card-category mb-1">
                        <h3>
                          <b
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              fontSize: 27,
                            }}
                          >
                            Pencapaian
                          </b>
                        </h3>
                      </p>
                      <Card.Title
                        as="h2"
                        style={{
                          color: hasilPresentase > 100 ? "#26DB53" : "#FF4D4F",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                        }}
                      >
                        {loadingPresentase ? (
                          <SkeletonTheme
                            color="#BDC7C9"
                            highlightColor="#BDC7C9"
                          >
                            <Skeleton height={"100%"} width={100} />
                          </SkeletonTheme>
                        ) : (
                          <b>{hasilPresentase}%</b>
                        )}
                      </Card.Title>
                    </Col>
                    <Col className="d-flex flex-column align-items-center mb-3">
                      <p className="card-category mb-1">
                        <h3>
                          <b
                            style={{
                              color: "black",
                              fontWeight: "bold",
                              fontSize: 27,
                            }}
                          >
                            Target
                          </b>
                        </h3>
                      </p>
                      <Card.Title
                        as="h2"
                        style={{
                          color: "#26DB53",
                          fontFamily: "inherit",
                          fontWeight: "bold",
                        }}
                      >
                        <b>100%</b>
                      </Card.Title>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col lg="6" sm="6">
            <Card style={{ border: 0, backgroundColor: "#F7F7F8" }}>
              <Row className="mb-3">
                <Col md="6">
                  <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
                    Tanggal Awal
                  </h4>
                  <Form.Group
                    style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                  >
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Date Picker Here",
                        readOnly: true,
                        style: {
                          cursor: "default",
                          color: "black",
                          backgroundColor: "white",
                        },
                      }}
                      onChange={(e) => {
                        e.toDate().setHours(0, 0, 0, 0),
                          setDariTgl(e.toDate().setHours(0, 0, 0, 0));
                      }}
                      value={dariTgl}
                      initialValue={dariTgl}
                      timeFormat={false}
                    ></ReactDatetime>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <h4 className="title mt-2" style={{ fontWeight: "bold" }}>
                    Tanggal Akhir
                  </h4>
                  <Form.Group
                    style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                  >
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Date Picker Here",
                        readOnly: true,
                        style: {
                          cursor: "default",
                          color: "black",
                          backgroundColor: "white",
                        },
                      }}
                      onChange={(e) => {
                        e.toDate().setHours(23, 59, 0, 0),
                          setSmpTgl(e.toDate().setHours(23, 59, 0, 0));
                      }}
                      value={smpTgl}
                      initialValue={smpTgl}
                      timeFormat={false}
                    ></ReactDatetime>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col lg="6" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                      border: 0,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#3C4151",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                          color: "white",
                        }}
                      >
                        <b>Total Pencairan</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body
                      style={{
                        textAlign: "center",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {formatRupiah(totalNominalPencairan)}
                    </Card.Body>
                    <Row noGutters>
                      <Col
                        className="p-1 d-flex justify-content-center"
                        style={{
                          fontWeight: "bold",
                          fontSize: 12,
                          borderRight: "1px solid #ddd",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Konsumtif
                      </Col>
                      <Col
                        className="p-1 d-flex justify-content-center"
                        style={{
                          fontWeight: "bold",
                          fontSize: 12,
                          whiteSpace: "nowrap",
                        }}
                      >
                        Produktif
                      </Col>
                    </Row>
                    <Row noGutters>
                      <Col
                        className="p-2 d-flex justify-content-center"
                        style={{
                          borderRight: "1px solid #ddd",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: 12,
                        }}
                      >
                        {formatRupiah(totalNominalPencairanKonsumtif)}
                      </Col>
                      <Col
                        className="p-2 d-flex justify-content-center"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: 12,
                        }}
                      >
                        {formatRupiah(totalNominalPencairanProduktif)}
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col lg="6" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                      border: 0,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#3C4151",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>Total Target</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {`Rp ${totalNominalTarget.toLocaleString("id-ID")}`}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg="6" sm="6" style={{ marginTop: 12 }}>
            <h4
              className="title mt-2"
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              Informasi
              <hr></hr>
            </h4>
            <Row>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                    marginTop: 8,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#607C8E",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Area Manager</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {totalAM.totalAm} Karyawan
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                    marginTop: 8,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#607C8E",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Supervisor</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {totalSpv.totalSpv} Karyawan
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                    marginTop: 8,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#607C8E",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Marketing</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {totalMarketing.totalSm} Karyawan
                  </Card.Body>
                </Card>
              </Col>
              {auth.role === "admin" || auth.role === "super admin" ? (
                <Col lg="4" sm="6">
                  <Card
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: 10,
                    }}
                  >
                    <Card.Header
                      className="d-none d-md-inline"
                      style={{
                        backgroundColor: "#607C8E",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                    >
                      <Card.Title
                        as="h6"
                        style={{
                          textAlign: "center",
                          alignItems: "center",
                          marginBottom: 10,
                          fontWeight: "bold",
                        }}
                      >
                        <b style={{ color: "white" }}>SM Sudah Login</b>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body style={{ textAlign: "center" }}>
                      {totalMarketing.totalLogin} Karyawan
                    </Card.Body>
                  </Card>
                </Col>
              ) : null}

              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#607C8E",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Cabang</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {totalCbg.totalCabang} Cabang
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col lg="6" sm="6" style={{ marginTop: 0 }}>
            <h4
              className="title mt-2"
              style={{ fontWeight: "bold", textTransform: "uppercase" }}
            >
              Status
              {auth.role === "super admin" ||
              auth.role === "admin" ||
              auth.role === "client" ? (
                <Button
                  className="btn-wd mr-1"
                  onClick={() => {
                    setModalExport(true);
                  }}
                  // variant="success"
                  style={{
                    marginLeft: 20,
                    backgroundColor: "#79AC78",
                    borderColor: "#79AC78",
                    fontWeight: "bold",
                  }}
                >
                  <i
                    className="fas fa-file-excel fa-lg"
                    style={{ marginRight: 10 }}
                  ></i>
                  Export Pengajuan
                </Button>
              ) : null}
              <hr></hr>
            </h4>
            <Row>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#607C8E",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>menunggu</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {listAllTotalPengajuan &&
                      listAllTotalPengajuan.map((item, index) => (
                        <div key={index}>
                          {item["Menunggu Konfirmasi"] && (
                            <p
                              style={{
                                fontSize: 24,
                                fontWeight: "bold",
                                margin: 0,
                              }}
                            >
                              {item["Menunggu Konfirmasi"].count}
                            </p>
                          )}
                        </div>
                      ))}
                  </Card.Body>
                  <Row noGutters>
                    <Col
                      className="p-1 d-flex justify-content-center"
                      style={{
                        fontWeight: "bold",
                        fontSize: 12,
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      Konsumtif
                    </Col>
                    <Col
                      className="p-1 d-flex justify-content-center"
                      style={{ fontWeight: "bold", fontSize: 12 }}
                    >
                      Produktif
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col
                      className="p-2 d-flex justify-content-center"
                      style={{ borderRight: "1px solid #ddd" }}
                    >
                      {listAllTotalPengajuan &&
                        listAllTotalPengajuan.map((item, index) => (
                          <div key={index}>
                            {item["Menunggu Konfirmasi"] && (
                              <p style={{ margin: 0 }}>
                                {item["Menunggu Konfirmasi"].konsumtif}
                              </p>
                            )}
                          </div>
                        ))}
                    </Col>
                    <Col className="p-2 d-flex justify-content-center">
                      {listAllTotalPengajuan &&
                        listAllTotalPengajuan.map((item, index) => (
                          <div key={index}>
                            {item["Menunggu Konfirmasi"] && (
                              <p style={{ margin: 0 }}>
                                {item["Menunggu Konfirmasi"].produktif}
                              </p>
                            )}
                          </div>
                        ))}
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#607C8E",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>diajukan</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {listAllTotalPengajuan &&
                      listAllTotalPengajuan.map((item, index) => (
                        <div key={index}>
                          {item["Diajukan"] && (
                            <p
                              style={{
                                fontSize: 24,
                                fontWeight: "bold",
                                margin: 0,
                              }}
                            >
                              {item["Diajukan"].count}
                            </p>
                          )}
                        </div>
                      ))}
                  </Card.Body>
                  <Row noGutters>
                    <Col
                      className="p-1 d-flex justify-content-center"
                      style={{
                        fontWeight: "bold",
                        fontSize: 12,
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      Konsumtif
                    </Col>
                    <Col
                      className="p-1 d-flex justify-content-center"
                      style={{ fontWeight: "bold", fontSize: 12 }}
                    >
                      Produktif
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col
                      className="p-2 d-flex justify-content-center"
                      style={{ borderRight: "1px solid #ddd" }}
                    >
                      {listAllTotalPengajuan &&
                        listAllTotalPengajuan.map((item, index) => (
                          <div key={index}>
                            {item["Diajukan"] && (
                              <p style={{ margin: 0 }}>
                                {item["Diajukan"].konsumtif}
                              </p>
                            )}
                          </div>
                        ))}
                    </Col>
                    <Col className="p-2 d-flex justify-content-center">
                      {listAllTotalPengajuan &&
                        listAllTotalPengajuan.map((item, index) => (
                          <div key={index}>
                            {item["Diajukan"] && (
                              <p style={{ margin: 0 }}>
                                {item["Diajukan"].produktif}
                              </p>
                            )}
                          </div>
                        ))}
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#607C8E",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Revisi</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {listAllTotalPengajuan &&
                      listAllTotalPengajuan.map((item, index) => (
                        <div key={index}>
                          {item["Revisi"] && (
                            <p
                              style={{
                                fontSize: 24,
                                fontWeight: "bold",
                                margin: 0,
                              }}
                            >
                              {item["Revisi"].count}
                            </p>
                          )}
                        </div>
                      ))}
                  </Card.Body>
                  <Row noGutters>
                    <Col
                      className="p-1 d-flex justify-content-center"
                      style={{
                        fontWeight: "bold",
                        fontSize: 12,
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      Konsumtif
                    </Col>
                    <Col
                      className="p-1 d-flex justify-content-center"
                      style={{ fontWeight: "bold", fontSize: 12 }}
                    >
                      Produktif
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col
                      className="p-2 d-flex justify-content-center"
                      style={{ borderRight: "1px solid #ddd" }}
                    >
                      {listAllTotalPengajuan &&
                        listAllTotalPengajuan.map((item, index) => (
                          <div key={index}>
                            {item["Revisi"] && (
                              <p style={{ margin: 0 }}>
                                {item["Revisi"].konsumtif}
                              </p>
                            )}
                          </div>
                        ))}
                    </Col>
                    <Col className="p-2 d-flex justify-content-center">
                      {listAllTotalPengajuan &&
                        listAllTotalPengajuan.map((item, index) => (
                          <div key={index}>
                            {item["Revisi"] && (
                              <p style={{ margin: 0 }}>
                                {item["Revisi"].produktif}
                              </p>
                            )}
                          </div>
                        ))}
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#607C8E",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Diproses</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {listAllTotalPengajuan &&
                      listAllTotalPengajuan.map((item, index) => (
                        <div key={index}>
                          {item["Diproses"] && (
                            <p
                              style={{
                                fontSize: 24,
                                fontWeight: "bold",
                                margin: 0,
                              }}
                            >
                              {item["Diproses"].count}
                            </p>
                          )}
                        </div>
                      ))}
                  </Card.Body>
                  <Row noGutters>
                    <Col
                      className="p-1 d-flex justify-content-center"
                      style={{
                        fontWeight: "bold",
                        fontSize: 12,
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      Konsumtif
                    </Col>
                    <Col
                      className="p-1 d-flex justify-content-center"
                      style={{ fontWeight: "bold", fontSize: 12 }}
                    >
                      Produktif
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col
                      className="p-2 d-flex justify-content-center"
                      style={{ borderRight: "1px solid #ddd" }}
                    >
                      {listAllTotalPengajuan &&
                        listAllTotalPengajuan.map((item, index) => (
                          <div key={index}>
                            {item["Diproses"] && (
                              <p style={{ margin: 0 }}>
                                {item["Diproses"].konsumtif}
                              </p>
                            )}
                          </div>
                        ))}
                    </Col>
                    <Col className="p-2 d-flex justify-content-center">
                      {listAllTotalPengajuan &&
                        listAllTotalPengajuan.map((item, index) => (
                          <div key={index}>
                            {item["Diproses"] && (
                              <p style={{ margin: 0 }}>
                                {item["Diproses"].produktif}
                              </p>
                            )}
                          </div>
                        ))}
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#607C8E",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                      position: "relative",
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      <b>Disetujui</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {listAllTotalPengajuan &&
                      listAllTotalPengajuan.map((item, index) => (
                        <div key={index}>
                          {item["Disetujui"] && (
                            <p
                              style={{
                                fontSize: 24,
                                fontWeight: "bold",
                                margin: 0,
                              }}
                            >
                              {item["Disetujui"].count}
                            </p>
                          )}
                        </div>
                      ))}
                  </Card.Body>
                  <Row noGutters>
                    <Col
                      className="p-1 d-flex justify-content-center"
                      style={{
                        fontWeight: "bold",
                        fontSize: 12,
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      Konsumtif
                    </Col>
                    <Col
                      className="p-1 d-flex justify-content-center"
                      style={{ fontWeight: "bold", fontSize: 12 }}
                    >
                      Produktif
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col
                      className="p-2 d-flex justify-content-center"
                      style={{ borderRight: "1px solid #ddd" }}
                    >
                      {listAllTotalPengajuan &&
                        listAllTotalPengajuan.map((item, index) => (
                          <div key={index}>
                            {item["Disetujui"] && (
                              <p style={{ margin: 0 }}>
                                {item["Disetujui"].konsumtif}
                              </p>
                            )}
                          </div>
                        ))}
                    </Col>
                    <Col className="p-2 d-flex justify-content-center">
                      {listAllTotalPengajuan &&
                        listAllTotalPengajuan.map((item, index) => (
                          <div key={index}>
                            {item["Disetujui"] && (
                              <p style={{ margin: 0 }}>
                                {item["Disetujui"].produktif}
                              </p>
                            )}
                          </div>
                        ))}
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col lg="4" sm="6">
                <Card
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderRadius: 10,
                  }}
                >
                  <Card.Header
                    className="d-none d-md-inline"
                    style={{
                      backgroundColor: "#607C8E",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  >
                    <Card.Title
                      as="h6"
                      style={{
                        textAlign: "center",
                        alignItems: "center",
                        marginBottom: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <b style={{ color: "white" }}>Ditolak</b>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body style={{ textAlign: "center" }}>
                    {listAllTotalPengajuan &&
                      listAllTotalPengajuan.map((item, index) => (
                        <div key={index}>
                          {item["Ditolak"] && (
                            <p
                              style={{
                                fontSize: 24,
                                fontWeight: "bold",
                                margin: 0,
                              }}
                            >
                              {item["Ditolak"].count}
                            </p>
                          )}
                        </div>
                      ))}
                  </Card.Body>
                  <Row noGutters>
                    <Col
                      className="p-1 d-flex justify-content-center"
                      style={{
                        fontWeight: "bold",
                        fontSize: 12,
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      Konsumtif
                    </Col>
                    <Col
                      className="p-1 d-flex justify-content-center"
                      style={{ fontWeight: "bold", fontSize: 12 }}
                    >
                      Produktif
                    </Col>
                  </Row>
                  <Row noGutters>
                    <Col
                      className="p-2 d-flex justify-content-center"
                      style={{ borderRight: "1px solid #ddd" }}
                    >
                      {listAllTotalPengajuan &&
                        listAllTotalPengajuan.map((item, index) => (
                          <div key={index}>
                            {item["Ditolak"] && (
                              <p style={{ margin: 0 }}>
                                {item["Ditolak"].konsumtif}
                              </p>
                            )}
                          </div>
                        ))}
                    </Col>
                    <Col className="p-2 d-flex justify-content-center">
                      {listAllTotalPengajuan &&
                        listAllTotalPengajuan.map((item, index) => (
                          <div key={index}>
                            {item["Ditolak"] && (
                              <p style={{ margin: 0 }}>
                                {item["Ditolak"].produktif}
                              </p>
                            )}
                          </div>
                        ))}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h3" style={{ fontWeight: "bold" }}>
                  Cabang
                  <hr />
                </Card.Title>
              </Card.Header>
              <Row>
                <Col md="12">
                  <div>
                    <div style={{ textAlign: "center" }}>
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor: "#8067DC",
                          width: 15,
                          height: 15,
                          marginRight: 5,
                        }}
                      ></div>
                      <label style={{ fontSize: 14 }}>Target cabang</label>
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor: "#66B7DC",
                          width: 15,
                          height: 15,
                          marginLeft: 20,
                          marginRight: 5,
                        }}
                      ></div>
                      <label style={{ fontSize: 14 }}>
                        Pencairan Cabang Keseluruhan
                      </label>
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor: "#6794DB",
                          width: 15,
                          height: 15,
                          marginRight: 5,
                          marginLeft: 20,
                        }}
                      ></div>
                      <label style={{ fontSize: 14 }}>
                        Pencairan cabang Konsumtif
                      </label>
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor: "#6771DC",
                          width: 15,
                          height: 15,
                          marginLeft: 20,
                          marginRight: 5,
                        }}
                      ></div>
                      <label style={{ fontSize: 14 }}>
                        Pencairan Cabang Produktif
                      </label>
                    </div>

                    <VictoryChart
                      height={280}
                      width={850}
                      style={{ parent: { marginLeft: "1rem" } }}
                      domainPadding={{ x: 50 }}
                    >
                      <VictoryGroup
                        offset={12}
                        colorScale={[
                          "#8067DC",
                          "#66B7DC",
                          "#6794DB",
                          "#6771DC",
                        ]}
                      >
                        <VictoryBar
                          data={chartData.series[1].map((yValue, index) => {
                            const yInBillion = yValue / 1000000000;
                            const decimalDigits = yInBillion
                              .toString()
                              .split(".")[1];
                            // const dyValue = decimalDigits && decimalDigits.length > 2 ? -5 : -10;

                            return {
                              x: chartData.labels[index],
                              y: yInBillion,
                              label: `${yInBillion}`,
                              // dy: dyValue,
                            };
                          })}
                          barWidth={10}
                          labelComponent={
                            <VictoryTooltip
                              pointerLength={0}
                              flyoutStyle={{
                                stroke: "none",
                                fill: "#8067DC",
                                padding: 5,
                              }}
                              dy={-2}
                              style={{
                                fontSize: 10,
                                fill: "white",
                              }}
                            />
                          }
                        />

                        <VictoryBar
                          data={chartData.series[0].map((yValue, index) => {
                            const yInBillion = yValue / 1000000000;
                            const decimalDigits = yInBillion
                              .toString()
                              .split(".")[1];
                            // const dyValue =
                            //   decimalDigits && decimalDigits.length > 2
                            //     ? -5
                            //     : -10;

                            return {
                              x: chartData.labels[index],
                              y: yInBillion,
                              label: `${yInBillion}`,
                              // dy: dyValue,
                            };
                          })}
                          barWidth={10}
                          labelComponent={
                            <VictoryTooltip
                              pointerLength={0}
                              flyoutStyle={{
                                stroke: "none",
                                fill: "#66B7DC",
                                padding: 5,
                              }}
                              dy={-2}
                              style={{
                                fontSize: 10,
                                fill: "white",
                              }}
                            />
                          }
                        />
                        <VictoryBar
                          data={chartData.series[2].map((yValue, index) => {
                            const yInBillion = yValue / 1000000000;
                            const decimalDigits = yInBillion
                              .toString()
                              .split(".")[1];
                            // const dyValue =
                            //   decimalDigits && decimalDigits.length > 2
                            //     ? -5
                            //     : -10;

                            return {
                              x: chartData.labels[index],
                              y: yInBillion,
                              label: `${yInBillion}`,
                              // dy: dyValue,
                            };
                          })}
                          barWidth={10}
                          labelComponent={
                            <VictoryTooltip
                              pointerLength={0}
                              flyoutStyle={{
                                stroke: "none",
                                fill: "#6794DB",
                                padding: 5,
                              }}
                              dy={-2}
                              style={{
                                fontSize: 10,
                                fill: "white",
                              }}
                            />
                          }
                        />
                        <VictoryBar
                          data={chartData.series[3].map((yValue, index) => {
                            const yInBillion = yValue / 1000000000;
                            const decimalDigits = yInBillion
                              .toString()
                              .split(".")[1];
                            // const dyValue =
                            //   decimalDigits && decimalDigits.length > 2
                            //     ? -5
                            //     : -10;

                            return {
                              x: chartData.labels[index],
                              y: yInBillion,
                              label: `${yInBillion}`,
                              // dy: dyValue,
                            };
                          })}
                          barWidth={10}
                          labelComponent={
                            <VictoryTooltip
                              pointerLength={0}
                              flyoutStyle={{
                                stroke: "none",
                                fill: "#6771DC",
                                padding: 5,
                              }}
                              dy={-2}
                              style={{
                                fontSize: 10,
                                fill: "white",
                              }}
                            />
                          }
                        />
                      </VictoryGroup>
                      <VictoryAxis
                        label="Nama Cabang"
                        tickLabelComponent={
                          <VictoryLabel style={{ fontSize: "10px" }} />
                        }
                        style={{
                          axisLabel: {
                            padding: 30,
                            fontSize: "8px",
                            fontStyle: "italic",
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <VictoryAxis
                        dependentAxis
                        tickCount={6}
                        label="Nominal Pencairan dalam Miliar"
                        style={{
                          axisLabel: {
                            padding: 42.5,
                            fontSize: "9px",
                            fontStyle: "italic",
                            fontWeight: "bold",
                          },
                          tickLabels: {
                            fontSize: "10px",
                            // padding: 30,
                            // textAnchor: "start",
                          },
                        }}
                        domain={
                          chartData.series.some((data) =>
                            data.slice(0, 10).some((yValue) => yValue !== 0)
                          )
                            ? undefined
                            : { y: [0, 1] }
                        }
                      />
                    </VictoryChart>
                  </div>
                </Col>
                <Col md="12" style={{ marginTop: 30 }}>
                  <Card
                    style={{
                      border: 0,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      borderRadius: 10,
                      marginRight: 30,
                      marginLeft: 30,
                      // backgroundColor: "#FFF59D",
                      color: "black",
                      padding: 20,
                    }}
                  >
                    <div style={{ marginBottom: 20 }}>
                      <Row>
                        <Col>
                          <h4
                            className="title mt-2"
                            style={{
                              fontWeight: "bold",
                              marginLeft: 20,
                              textAlign: "start",
                            }}
                          >
                            Keterangan Nama Cabang
                          </h4>
                        </Col>
                        <Col style={{ textAlign: "end" }}>
                          <Button
                            className="mr-1"
                            // variant="primary"
                            style={{
                              marginBottom: 10,
                              fontSize: 16,
                              backgroundColor: "#F75D59",
                              border: 0,
                              paddingLeft: 10,
                              paddingRight: 10,
                              fontWeight: "bold",
                            }}
                            onClick={() => setModalDetail(!modalDetail)}
                          >
                            Detail Semua Cabang
                          </Button>
                        </Col>
                      </Row>
                    </div>

                    <div
                      style={{
                        marginLeft: 20,
                        flexWrap: "wrap",
                        display: "flex",
                      }}
                    >
                      {dataGrafikCabang.map((cabang, index) => {
                        return (
                          <div
                            style={{
                              borderWidth: 2,
                              width: 300,
                              marginTop: 10,
                            }}
                          >
                            <Row key={index}>
                              <Col sm="1">
                                <label
                                  style={{
                                    textTransform: "none",
                                    fontSize: 14,
                                    color: "black",
                                    fontWeight: "bold",
                                    color: "black",
                                  }}
                                >
                                  {String.fromCharCode(65 + index)}
                                </label>
                              </Col>
                              <Col sm="o">
                                <label
                                  style={{
                                    textTransform: "none",
                                    fontSize: 14,
                                    color: "black",

                                    color: "black",
                                  }}
                                >
                                  :
                                </label>
                              </Col>
                              <Col sm="9">
                                <label
                                  style={{
                                    textTransform: "none",
                                    fontSize: 14,
                                    color: "black",

                                    color: "black",
                                  }}
                                >
                                  {cabang.nama}
                                </label>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}

                      {/* <Row>
                        <Col md="6">
                          {dataGrafikCabang.map((cabang, index) =>
                            index < 5 ? (
                              <Row key={index}>
                                <Col sm="1">
                                 
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      fontWeight: "bold",
                                      color: "black",
                                    }}
                                  >
                                    {String.fromCharCode(65 + index)}
                                  </label>
                                  
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="9">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      color: "black",
                                    }}
                                  >
                                    {cabang.nama}
                                  </label>
                                </Col>
                              </Row>
                            ) : null
                          )}
                        </Col>

                        <Col md="6">
                          {dataGrafikCabang.map((cabang, index) =>
                            index >= 5 ? (
                              <Row key={index}>
                                <Col sm="1">
                                
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      fontWeight: "bold",
                                      color: "black",
                                    }}
                                  >
                                    {String.fromCharCode(65 + index)}
                                  </label>
                        
                                </Col>
                                <Col sm="o">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      color: "black",
                                    }}
                                  >
                                    :
                                  </label>
                                </Col>
                                <Col sm="9">
                                  <label
                                    style={{
                                      textTransform: "none",
                                      fontSize: 16,
                                      color: "black",
                                      marginTop: 10,
                                      color: "black",
                                    }}
                                  >
                                    {cabang.nama}
                                  </label>
                                </Col>
                              </Row>
                            ) : null
                          )}
                        </Col>
                      </Row> */}
                    </div>
                    {/* <div
                      style={{
                        textAlign: "end",
                      }}
                    >
                      <Button
                        className="btn-wd mr-1"
                        variant="primary"
                        style={{ marginTop: 15, marginBottom: 10 }}
                        onClick={() => setModalDetail(!modalDetail)}
                      >
                        Tampilkan Detail Semua Cabang
                      </Button>
                    </div> */}
                  </Card>
                </Col>
              </Row>

              {/* <ChartistGraph
                  data={chartData}
                  type="Bar"
                  options={{
                    seriesBarDistance: 10,
                    axisX: {
                      showGrid: false,
                    },
                    height: "245px",
                    labelInterpolationFnc: function (value, index) {
                      return index % 2 === 0 ? value : null; // Menampilkan setiap elemen genap
                    },
                  }}
                  // ...
                /> */}
              {/* </Card.Body> */}
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h3" style={{ fontWeight: "bold" }}>
                  <span> {auth.nama} </span>
                  <hr />
                </Card.Title>
                <Col md={"3"}>
                  <label style={{ color: "black", fontSize: 14 }}>
                    Pilih Tahun
                  </label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    defaultValue={{ label: yearNow, value: yearNow }}
                    onChange={(value) => {
                      setTahun(value.value);
                    }}
                    options={listYear}
                    // placeholder="Pilih Tahun"
                  />
                  {/* <Form.Control
                    onChange={(e) => {
                      setTahun(e.target.value);
                    }}
                    placeholder="Input Tahun"
                    type="month"
                  ></Form.Control> */}
                  {/* <p style={{ color: "gray", marginTop: 10 }}>
                    Tahun dan bulan saat ini :{" "}
                    {isDateValid
                      ? moment(tahun).format("MMMM YYYY")
                      : "Tidak ada data"}
                  </p> */}
                </Col>
              </Card.Header>
              <Row>
                <Col md="12">
                  <div>
                    <div style={{ textAlign: "center", marginTop: 15 }}>
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor: "#FDB915",
                          width: 15,
                          height: 15,
                          marginLeft: 20,
                          marginRight: 5,
                        }}
                      ></div>
                      <label style={{ fontSize: 14 }}>Target User</label>
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor: "#00E396",
                          width: 15,
                          height: 15,
                          marginLeft: 20,
                          marginRight: 5,
                        }}
                      ></div>
                      <label style={{ fontSize: 14 }}>
                        Pencairan User Keseluruhan
                      </label>
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor: "#0000FF",
                          width: 15,
                          height: 15,
                          marginLeft: 20,
                          marginRight: 5,
                        }}
                      ></div>
                      <label style={{ fontSize: 14 }}>
                        Pencairan User Konsumtif
                      </label>
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor: "#750DC2",
                          width: 15,
                          height: 15,
                          marginLeft: 20,
                          marginRight: 5,
                        }}
                      ></div>
                      <label style={{ fontSize: 14 }}>
                        Pencairan User Produktif
                      </label>
                    </div>
                    <VictoryChart
                      height={250}
                      width={850}
                      style={{ parent: { marginLeft: "1rem" } }}
                      domainPadding={{ x: 50 }}
                    >
                      <VictoryGroup
                        offset={12.5}
                        colorScale={[
                          "#FDB915",
                          "#00E396",
                          "#0000FF",
                          "#750DC2",
                        ]}
                      >
                        <VictoryBar
                          data={chartDataUser.series[0].map((yValue, index) => {
                            const yInBillion = yValue / 1000000000;
                            const decimalDigits = yInBillion
                              .toString()
                              .split(".")[1];
                            // const dyValue = decimalDigits && decimalDigits.length > 2 ? -5 : -10;

                            return {
                              x: chartDataUser.labels[index],
                              y: yInBillion,
                              label: `${yInBillion}`,
                              // dy: dyValue,
                            };
                          })}
                          barWidth={10}
                          labelComponent={
                            <VictoryTooltip
                              pointerLength={0}
                              flyoutStyle={{
                                stroke: "none",
                                fill: "#FDB915",
                                padding: 5,
                              }}
                              dy={-2}
                              style={{
                                fontSize: 10,
                                fill: "white",
                              }}
                            />
                          }
                        />

                        <VictoryBar
                          data={chartDataUser.series[1].map((yValue, index) => {
                            const yInBillion = yValue / 1000000000;
                            const decimalDigits = yInBillion
                              .toString()
                              .split(".")[1];
                            // const dyValue = decimalDigits && decimalDigits.length > 2 ? -5 : -10;

                            return {
                              x: chartDataUser.labels[index],
                              y: yInBillion,
                              label: `${yInBillion}`,
                              // dy: dyValue,
                            };
                          })}
                          barWidth={10}
                          labelComponent={
                            <VictoryTooltip
                              dy={-2}
                              pointerLength={0}
                              flyoutStyle={{
                                stroke: "none",
                                fill: "#00E396",
                                padding: 5,
                              }}
                              style={{
                                fontSize: 10,
                                fill: "white",
                              }}
                            />
                          }
                        />

                        <VictoryBar
                          data={chartDataUser.series[2].map((yValue, index) => {
                            const yInBillion = yValue / 1000000000;
                            const decimalDigits = yInBillion
                              .toString()
                              .split(".")[1];
                            // const dyValue = decimalDigits && decimalDigits.length > 2 ? -5 : -10;

                            return {
                              x: chartDataUser.labels[index],
                              y: yInBillion,
                              label: `${yInBillion}`,
                              // dy: dyValue,
                            };
                          })}
                          barWidth={10}
                          labelComponent={
                            <VictoryTooltip
                              pointerLength={0}
                              flyoutStyle={{
                                stroke: "none",
                                fill: "#0000FF",
                                padding: 5,
                              }}
                              dy={-2}
                              style={{
                                fontSize: 10,
                                fill: "white",
                              }}
                            />
                          }
                        />

                        <VictoryBar
                          data={chartDataUser.series[3].map((yValue, index) => {
                            const yInBillion = yValue / 1000000000;
                            const decimalDigits = yInBillion
                              .toString()
                              .split(".")[1];
                            // const dyValue = decimalDigits && decimalDigits.length > 2 ? -5 : -10;

                            return {
                              x: chartDataUser.labels[index],
                              y: yInBillion,
                              label: `${yInBillion}`,
                              // dy: dyValue,
                            };
                          })}
                          barWidth={10}
                          labelComponent={
                            <VictoryTooltip
                              pointerLength={0}
                              flyoutStyle={{
                                stroke: "none",
                                fill: "#750DC2",
                                padding: 5,
                              }}
                              dy={-2}
                              style={{
                                fontSize: 10,
                                fill: "white",
                              }}
                            />
                          }
                        />
                      </VictoryGroup>
                      <VictoryAxis
                        tickLabelComponent={
                          <VictoryLabel style={{ fontSize: "10px" }} />
                        }
                        label="Bulan"
                        style={{
                          axisLabel: {
                            padding: 30,
                            fontSize: "8px",
                            fontStyle: "italic",
                            fontWeight: "bold",
                          },
                        }}
                      />
                      <VictoryAxis
                        dependentAxis
                        label="Nominal Pencairan dalam Miliar"
                        style={{
                          axisLabel: {
                            padding: 42.5,
                            fontSize: "8px",
                            fontStyle: "italic",
                            fontWeight: "bold",
                          },
                          tickLabels: {
                            fontSize: "10px",
                          },
                        }}
                        domain={
                          chartDataUser.series.some((data) =>
                            data.some((yValue) => yValue !== 0)
                          )
                            ? undefined
                            : { y: [0, 1] }
                        }
                      />
                    </VictoryChart>
                  </div>
                </Col>
              </Row>

              {/* <ChartistGraph
                  data={chartData}
                  type="Bar"
                  options={{
                    seriesBarDistance: 10,
                    axisX: {
                      showGrid: false,
                    },
                    height: "245px",
                    labelInterpolationFnc: function (value, index) {
                      return index % 2 === 0 ? value : null; // Menampilkan setiap elemen genap
                    },
                  }}
                  // ...
                /> */}
              {/* </Card.Body> */}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
    // </SkeletonTheme>
  );
}

export default DashboardUtama;
