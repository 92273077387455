import React from "react";
import { Button, Card, Form, Col, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Label } from "reactstrap";

function Step4() {
  const pengajuan = useSelector((state) => state.pengajuanReducer);
  const valDetail = pengajuan.detailPengajuan;
  const formatRupiah = (angka) => {
    const numberFormat = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    });
    return numberFormat.format(angka);
  };
  // console.log(valDetail, "LLLLLLLLLL");

  return (
    <Col md="12" style={{ marginTop: 20 }}>
      <Card.Header>
        <Card.Title
          as="h4"
          style={{
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          infomrasi pinjaman
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Card style={{ border: 0 }}>
          <div style={{ maxHeight: "450px", overflowY: "scroll" }}>
            <div className="row">
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  tujuan penggunaan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.tujuanPenggunaan === "1"
                      ? "Konsumtif"
                      : "Produktif"}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  tenor
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.tenor}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  nominal permohonan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {formatRupiah(valDetail.stepEmpat.nominalPermohonan)}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  nominal pencairan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {formatRupiah(valDetail.nominalPencairan)}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  nip
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.nip}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  suku bunga
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.sukuBunga}%
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  Jenis Kredit
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.jenisKredit === "51"
                      ? "KPR Umum"
                      : valDetail.stepEmpat.jenisKredit === "52"
                      ? "KPR FLPP"
                      : valDetail.stepEmpat.jenisKredit === "53"
                      ? "KPR Gen Me 150-180 Bulan"
                      : valDetail.stepEmpat.jenisKredit === "54"
                      ? "KPR Gen Me 181-239 Bulan"
                      : valDetail.stepEmpat.jenisKredit === "55"
                      ? "KPR Gen Me 240-360 Bulan"
                      : valDetail.stepEmpat.jenisKredit === "56"
                      ? "Personal Loan Umum (Payroll)"
                      : valDetail.stepEmpat.jenisKredit === "57"
                      ? "Personal Loan Umum (Non Payroll)"
                      : valDetail.stepEmpat.jenisKredit === "58"
                      ? "Personal Loan PNSD / Pensiunan"
                      : valDetail.stepEmpat.jenisKredit === "59"
                      ? "Kredit Kendaraan Bermotor"
                      : valDetail.stepEmpat.jenisKredit === "60"
                      ? "Kredit Multiguna"
                      : valDetail.stepEmpat.jenisKredit === "61"
                      ? "Cash Collateral"
                      : valDetail.stepEmpat.jenisKredit === "1"
                      ? "KUR"
                      : valDetail.stepEmpat.jenisKredit === "2"
                      ? "KUM"
                      : valDetail.stepEmpat.jenisKredit === "3"
                      ? "4S"
                      : valDetail.stepEmpat.jenisKredit === "4"
                      ? "Mikro Bank Kaltimtara"
                      : valDetail.stepEmpat.jenisKredit === "5"
                      ? "KUR Super Mikro"
                      : valDetail.stepEmpat.jenisKredit === "6"
                      ? "KUR Ke I (Pertama) 6%"
                      : valDetail.stepEmpat.jenisKredit === "7"
                      ? "KUR Ke II (Kedua) 7%"
                      : valDetail.stepEmpat.jenisKredit === "8"
                      ? "KUR Ke III (Ketiga) 8%"
                      : "KUR Ke IV (Keempat) 9%"}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  detail tujuan penggunaan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.detailTujuanPenggunaan}
                  </Label>
                </li>
              </div>
              {valDetail.stepEmpat.tujuanPenggunaan === "1" ? (
                <>
                  <div className="col-6">
                    <Label
                      style={{
                        fontSize: 16,
                        marginTop: 15,
                        fontWeight: "bold",
                        color: "#828286",
                        textTransform: "uppercase",
                      }}
                    >
                      Fasilitas
                    </Label>
                    <li>
                      <Label
                        style={{
                          fontSize: 14,
                          marginBottom: 15,
                          color: "black",
                          textTransform: "capitalize",
                        }}
                      >
                        {valDetail.stepEmpat.fasilitas === "gaji"
                          ? "Gaji"
                          : valDetail.stepEmpat.fasilitas === "sertifikasi"
                          ? "Sertifikasi"
                          : valDetail.stepEmpat.fasilitas === "gabungan"
                          ? "Gaji & Sertifikasi"
                          : "-"}
                      </Label>
                    </li>
                  </div>
                </>
              ) : null}

              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  status pinjaman
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.statusPinjaman === "1"
                      ? "New"
                      : valDetail.stepEmpat.statusPinjaman === "2"
                      ? "Take Over"
                      : valDetail.stepEmpat.statusPinjaman === "3"
                      ? "Top Up"
                      : "Lainnya"}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  cabang pencairan
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.idCabangPencairan ===
                    valDetail.lokasiPencairan.namaCabang
                      ? valDetail.lokasiPencairan.namaCabang
                      : valDetail.lokasiPencairan.namaCabang}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  pembayaran gaji melalui
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.stepEmpat.pembayaranGajiMelalui === "1"
                      ? "PT BPD KALTIMTARA"
                      : valDetail.stepEmpat.pembayaranGajiMelalui === "2"
                      ? "Bendahara"
                      : valDetail.stepEmpat.pembayaranGajiMelalui === "3"
                      ? "Koperasi"
                      : valDetail.stepEmpat.pembayaranGajiMelalui === "4"
                      ? "Bank Lain"
                      : "Lainnya"}
                  </Label>
                </li>
              </div>
              <div className="col-6">
                <Label
                  style={{
                    fontSize: 16,
                    marginTop: 15,
                    fontWeight: "bold",
                    color: "#828286",
                    textTransform: "uppercase",
                  }}
                >
                  alamat cabang
                </Label>
                <li>
                  <Label
                    style={{
                      fontSize: 14,
                      marginBottom: 15,
                      color: "black",
                      textTransform: "capitalize",
                    }}
                  >
                    {valDetail.lokasiPencairan.alamat}
                  </Label>
                </li>
              </div>
            </div>
          </div>
        </Card>
      </Card.Body>
    </Col>
  );
}
export default Step4;
