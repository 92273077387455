import React from "react";
import { Card, Nav, Container, Row, Col, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import PenggajianUser from "./TabPenggajianUser/penggajianUser";
import PenggajianKorwil from "./TabPenggajianUser/penggajianKorwil";
import PenggajianAreaManager from "./TabPenggajianUser/penggajianAreaManager";
import PenggajianSupervisor from "./TabPenggajianUser/penggajianSupervisor";
import PenggajianMarketing from "./TabPenggajianUser/penggajianMarketing";

function IndexPenggajianUser() {
  const auth = useSelector((state) => state.authReducer);
  const [status, setStatus] = React.useState("");

  // React.useEffect(() => {
  //   setStatus(auth.role);
  // }, [auth.role]);
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title
                  as="h3"
                  style={{
                    color: "black",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                >
                  Payroll User
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Tab.Container
                  id="plain-tabs-example"
                  defaultActiveKey={
                    auth.role === "super admin"
                      ? "penggajianUser"
                      : "penggajianKorwil"
                  }
                >
                  <Nav role="tablist" variant="tabs">
                    {auth.role === "super admin" ? (
                      <Nav.Item>
                        <Nav.Link
                          eventKey="penggajianUser"
                          style={{ color: "#3ABEEA" }}
                        >
                          User
                        </Nav.Link>
                      </Nav.Item>
                    ) : null}
                    <Nav.Item>
                      <Nav.Link
                        eventKey="penggajianKorwil"
                        style={{ color: "#3ABEEA" }}
                      >
                        Koordinator Wilayah
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="penggajianAreamaneger"
                        style={{ color: "#3ABEEA" }}
                      >
                        Area Manager
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="penggajianSupervisor"
                        style={{ color: "#3ABEEA" }}
                      >
                        Supervisor
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="penggajianMarketing"
                        style={{ color: "#3ABEEA" }}
                      >
                        Sales Marketing
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="penggajianUser">
                      <PenggajianUser />
                    </Tab.Pane>

                    <Tab.Pane eventKey="penggajianKorwil">
                      <PenggajianKorwil />
                    </Tab.Pane>

                    <Tab.Pane eventKey="penggajianAreamaneger">
                      <PenggajianAreaManager />
                    </Tab.Pane>

                    <Tab.Pane eventKey="penggajianSupervisor">
                      <PenggajianSupervisor />
                    </Tab.Pane>

                    <Tab.Pane eventKey="penggajianMarketing">
                      <PenggajianMarketing />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default IndexPenggajianUser;
