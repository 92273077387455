import { baseAxios } from "../index";

export async function tambahAreaManager(data) {
  try {
    const response = await baseAxios.post(
      "webAreaManager/tambahAreaManager",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function getAllAreaManager(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `webAreaManager/getAllAreaManager?idClient=${data.idClient}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALLAREAMANAGER", data: response.data });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function penempatanAreaManager(data) {
  try {
    const response = await baseAxios.post(
      "webAreaManager/penempatanAreaManager",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function ubahPenempatanAreaManager(data) {
  try {
    const response = await baseAxios.post("webAreaManager/ubahAmCabang", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function tambahTargetAreaManager(data) {
  try {
    const response = await baseAxios.post(
      "webAreaManager/tambahTargetAreaManager",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    console.log("error upload", err);
    return err.response;
  }
}

export async function getAllTargetAreaManager(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `webAreaManager/getAllTargetAreaManager?idClient=${data.idClient}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALL_TARGET_AREAMANAGER", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function editTargetAreaManager(data) {
  try {
    const response = await baseAxios.patch(
      "webAreaManager/ubahTargetAreaManager",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}

export async function editStatusAreaManager(data) {
  try {
    const response = await baseAxios.patch(
      "webAreaManager/ubahStatusAreaManager",
      data,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    return response;
  } catch (err) {
    throw err.response.data;
  }
}
