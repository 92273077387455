import React, { useState } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import Swal from "sweetalert2";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Tab,
  Modal,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { tambahCabang, getAllCabang, getAllClient } from "stores";
import { getAllAnggotaCabang } from "stores";
import { getAllSupervisor } from "stores";
import { getAllAreaManager } from "stores";
import { ubahPenempatanSupervisor } from "stores";
import { ubahPenempatanAreaManager } from "stores";

function ListCabang() {
  const dispatch = useDispatch();
  // const [singleSelect, setSingleSelect] = React.useState("");
  const cabang = useSelector((state) => state.cabangReducer);
  const storeClient = useSelector((state) => state.clientReducer);
  const storeSpv = useSelector((state) => state.supervisorReducer);
  const storeAM = useSelector((state) => state.areaManagerReducer);
  const auth = useSelector((state) => state.authReducer);
  const [modalCabang, setModalCabang] = React.useState(false);
  const [modalDetail, setModalDetail] = React.useState(false);
  const [modalPenempatanSupervisor, setModalPenempatanSupervisor] =
    React.useState(false);
  const [modalPenempatanAM, setModalPenempatanAM] = React.useState(false);
  const [namaCabang, setNamaCabang] = React.useState("");
  const [idCabang, setIdCabang] = React.useState("");
  const [pilihClient, setPilihClient] = React.useState(null);
  const [filterClient, setFilterClient] = React.useState(null);
  const [alamat, setAlamat] = React.useState("");
  const [listCabang, setListCabang] = React.useState([]);
  const [listClient, setListClient] = React.useState([]);
  const [idClientCbg, setIdClientCbg] = React.useState("");
  const [detailCabang, setDetailCabang] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [listSpv, setListSpv] = React.useState([]);
  const [listAM, setListAM] = React.useState([]);
  const [pilihSpv, setPilihSpv] = React.useState(null);
  const [pilihAM, setPilihAM] = React.useState(null);
  const [idSsBaru, setidSsBaru] = React.useState(null);
  const [idAmBaru, setidAmBaru] = React.useState(null);

  const itemsPerPage = 10;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleFilterChange = (selectedOption) => {
    setFilterClient(selectedOption);
    getAllCabang(dispatch, { idClient: selectedOption.value });
  };

  const submitCabang = () => {
    tambahCabang({
      cabang_id: idCabang,
      namaCabang: namaCabang,
      alamat: alamat,
      idClient: idClientCbg,
    })
      .then((response) => {
        if (response.data.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1000,
          });
          handleModalClose();
          getAllCabang(dispatch, { idClient: "" });
        } else if (response.data.status === 403) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      })
      .catch((error) => {
        console.log("ERRORRRR", error);
      });
  };

  const submitUbahPenempatanSS = () => {
    if (pilihSpv === null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Pemilihan Supervisor tidak boleh kosong",
      });
    } else {
      ubahPenempatanSupervisor({
        idCabang: idCabang,
        idSsLama: cabang.listAllAnggotaCabang.listSupervisor[0].idUser,
        idSsBaru: idSsBaru,
      }).then((response) => {
        if (response.data.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1000,
          });
          handleModalClose();
          setIdCabang("");
          setPilihSpv(null);
          setidSsBaru(null);
          getAllCabang(dispatch, { idClient: "" });
        } else if (response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      });
    }
  };

  const submitUbahPenempatanAM = () => {
    // console.log("idCabang", idCabang, "idSsBaru", idAmBaru);
    // console.log(cabang.listAllAnggotaCabang.listAreaManager[0].idUser);
    if (pilihAM === null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Pemilihan Area Manager tidak boleh kosong",
      });
    } else {
      ubahPenempatanAreaManager({
        idCabang: idCabang,
        idAmLama: cabang.listAllAnggotaCabang.listAreaManager[0].idUser,
        idAmBaru: idAmBaru,
      }).then((response) => {
        if (response.data.status === 200) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1000,
          });
          handleModalClose();
          setIdCabang("");
          setPilihAM(null);
          setidAmBaru(null);
          getAllCabang(dispatch, { idClient: "" });
        } else if (response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
        }
      });
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleModalClose = () => {
    setModalCabang(false);
    setPilihClient("");
    setIdClientCbg("");
    setIdCabang("");
    setAlamat("");
    setNamaCabang("");
    setPilihSpv("");
    setPilihAM("");
    setModalPenempatanSupervisor(false);
    setModalPenempatanAM(false);
  };

  React.useEffect(() => {
    let tmp =
      cabang.listCabang &&
      cabang.listCabang.map((val) => {
        return {
          ...val,
          action: (
            <>
              {" "}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Details Cabang</Tooltip>}
              >
                <Button
                  className="mr-0"
                  // variant="info"
                  style={{
                    marginTop: 5,
                    backgroundColor: "#F75D59",
                    border: 0,
                  }}
                  onClick={() => {
                    // console.log(val);
                    setModalDetail(!modalDetail);
                    setDetailCabang(val);
                    getAllAnggotaCabang(dispatch, { idCabang: val._id });
                  }}
                >
                  <i class="fas fa-info"></i>
                </Button>
              </OverlayTrigger>{" "}
              {auth.role === "super admin" || auth.role === "admin" ? (
                <>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Ubah Penempatan SS</Tooltip>}
                  >
                    <Button
                      className="mr-0"
                      style={{
                        marginTop: 5,
                        backgroundColor: "#42CDC6",
                        border: 0,
                      }}
                      onClick={() => {
                        setModalPenempatanSupervisor(
                          !modalPenempatanSupervisor
                        );
                        getAllSupervisor(dispatch, { idClient: "" });
                        setIdCabang(val._id);
                        getAllAnggotaCabang(dispatch, { idCabang: val._id });
                      }}
                    >
                      <i className="fas fa-map-marked-alt"></i>
                    </Button>
                  </OverlayTrigger>{" "}
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Ubah Penempatan AM</Tooltip>}
                  >
                    <Button
                      className="mr-0"
                      style={{
                        marginTop: 5,
                        backgroundColor: "#013E62",
                        border: 0,
                      }}
                      onClick={() => {
                        setModalPenempatanAM(!modalPenempatanAM);
                        getAllAreaManager(dispatch, { idClient: "" });
                        setIdCabang(val._id);
                        getAllAnggotaCabang(dispatch, { idCabang: val._id });
                      }}
                    >
                      <i className="fas fa-map-marked-alt"></i>
                    </Button>
                  </OverlayTrigger>
                </>
              ) : null}
            </>
          ),
        };
      });
    setListCabang(tmp);
  }, [cabang.listCabang]);

  React.useEffect(() => {
    let tmp = [
      {
        value: "",
        isDisabled: true,
      },
    ];
    storeClient.listClient &&
      storeClient.listClient.map((val) => {
        tmp.push({
          value: val._id,
          label: val.namaClient,
        });
      });

    setListClient(tmp);
  }, [storeClient.listClient]);

  React.useEffect(() => {
    let tmp = [
      {
        value: "",
        isDisabled: true,
      },
    ];
    storeSpv.listSupervisor &&
      storeSpv.listSupervisor.map((val) => {
        tmp.push({
          value: val._id,
          label: val.nama,
        });
      });

    setListSpv(tmp);
  }, [storeSpv.listSupervisor]);

  React.useEffect(() => {
    let tmp = [
      {
        value: "",
        isDisabled: true,
      },
    ];
    storeAM.listAreaManager &&
      storeAM.listAreaManager.map((val) => {
        tmp.push({
          value: val._id,
          label: val.nama,
        });
      });

    setListAM(tmp);
  }, [storeAM.listAreaManager]);

  const customStyles = {
    rows: {
      style: {
        height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 18,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const colCabang = [
    {
      name: "No",
      width: "100px",
      center: true,
      selector: (row, index) => (currentPage - 1) * itemsPerPage + index + 1,
    },
    {
      name: "Kode Cabang",
      minWidth: "100px",
      center: true,
      selector: (row) => row.cabang_id,
    },
    {
      name: "Nama Cabang",
      minWidth: "200px",
      center: true,
      selector: (row) => row.namaCabang,
    },
    {
      name: "BPD",
      minWidth: "200px",
      center: true,
      selector: (row) => row.client,
    },
    {
      name: "Aksi",
      minWidth: "200px",
      center: true,
      selector: (row) => row.action,
    },
  ];

  React.useEffect(() => {
    getAllCabang(dispatch, { idClient: "" });
    getAllClient(dispatch);
    getAllSupervisor(dispatch, { idClient: "" });
    getAllAreaManager(dispatch, { idClient: "" });
  }, []);

  return (
    <>
      <Modal
        size="lg"
        show={modalPenempatanAM}
        // onHide={() => setModalPenempatanSupervisor(!modalPenempatanSupervisor)}
        onHide={() => {
          handleModalClose();
          setModalPenempatanAM(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Ubah Penempatan Area Manager
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                {/* <Col md="6"> */}
                <Form.Group>
                  <label style={{ color: "black" }}>Pilih Area Manager</label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="Pilih Area Manager"
                    value={pilihAM}
                    onChange={(value) => {
                      setPilihAM(value);
                      setidAmBaru(value.value);
                      // setIdCabang(value.value);
                    }}
                    options={listAM}
                    placeholder="Pilih Area Manager"
                  />
                </Form.Group>
                {/* </Col> */}
              </Form>
            </Card.Body>
            <Card.Footer>
              <Button
                className="btn-wd mr-0"
                variant="primary"
                onClick={submitUbahPenempatanAM}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="lg"
        show={modalPenempatanSupervisor}
        // onHide={() => setModalPenempatanSupervisor(!modalPenempatanSupervisor)}
        onHide={() => {
          handleModalClose();
          setModalPenempatanSupervisor(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Ubah Penempatan Supervisor
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                {/* <Col md="6"> */}
                <Form.Group>
                  <label style={{ color: "black" }}>Pilih Supervisor</label>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="Pilih Supervisor"
                    value={pilihSpv}
                    onChange={(value) => {
                      setPilihSpv(value);
                      setidSsBaru(value.value);
                    }}
                    options={listSpv}
                    placeholder="Pilih Supervisor"
                  />
                </Form.Group>
                {/* </Col> */}
              </Form>
            </Card.Body>
            <Card.Footer>
              <Button
                className="btn-wd mr-0"
                variant="primary"
                onClick={submitUbahPenempatanSS}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="lg"
        show={modalDetail}
        onHide={() => setModalDetail(!modalDetail)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form" style={{ border: 0 }}>
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Detail Cabang
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Row>
                  <Col sm="2">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      BPD
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailCabang.client}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="2">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Cabang
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {detailCabang.namaCabang}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="2">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Area Manager
                    </label>
                  </Col>
                  <Col sm="o">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      <ul style={{ marginLeft: 15, padding: 0 }}>
                        {cabang.listAllAnggotaCabang.listAreaManager &&
                        cabang.listAllAnggotaCabang.listAreaManager.length >
                          0 ? (
                          cabang.listAllAnggotaCabang.listAreaManager.map(
                            (val) => (
                              <li key={val.namaAreaManager}>
                                <label
                                  style={{
                                    textTransform: "none",
                                    fontSize: 14,
                                    color: "black",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                >
                                  {val.namaAreaManager}
                                </label>
                              </li>
                            )
                          )
                        ) : (
                          <li>
                            <label
                              style={{
                                textTransform: "none",
                                fontSize: 14,
                                color: "black",
                                margin: 0,
                                padding: 0,
                                fontWeight: "bold",
                              }}
                            >
                              Belum Memiliki Anggota
                            </label>
                          </li>
                        )}
                      </ul>
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="2">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Supervisor
                    </label>
                  </Col>
                  <Col sm="0">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      <ul style={{ marginLeft: 15, padding: 0 }}>
                        {cabang.listAllAnggotaCabang.listSupervisor &&
                        cabang.listAllAnggotaCabang.listSupervisor.length >
                          0 ? (
                          cabang.listAllAnggotaCabang.listSupervisor.map(
                            (val) => (
                              <li key={val.namaSupervisor}>
                                <label
                                  style={{
                                    textTransform: "none",
                                    fontSize: 14,
                                    color: "black",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                >
                                  {val.namaSupervisor}
                                </label>
                              </li>
                            )
                          )
                        ) : (
                          <li>
                            <label
                              style={{
                                textTransform: "none",
                                fontSize: 14,
                                color: "black",
                                margin: 0,
                                padding: 0,
                                fontWeight: "bold",
                              }}
                            >
                              Belum Memiliki Anggota
                            </label>
                          </li>
                        )}
                      </ul>
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="2">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Marketing
                    </label>
                  </Col>
                  <Col sm="0">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      :
                    </label>
                  </Col>
                  <Col sm="6">
                    <label
                      style={{
                        textTransform: "none",
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      <ul style={{ marginLeft: 15, padding: 0 }}>
                        {cabang.listAllAnggotaCabang.listMarketing &&
                        cabang.listAllAnggotaCabang.listMarketing.length > 0 ? (
                          cabang.listAllAnggotaCabang.listMarketing.map(
                            (val) => (
                              <li key={val.namaMarketing}>
                                <label
                                  style={{
                                    textTransform: "none",
                                    fontSize: 14,
                                    color: "black",
                                    margin: 0,
                                    padding: 0,
                                  }}
                                >
                                  {val.namaMarketing}
                                </label>
                              </li>
                            )
                          )
                        ) : (
                          <li>
                            <label
                              style={{
                                textTransform: "none",
                                fontSize: 14,
                                color: "black",
                                margin: 0,
                                padding: 0,
                                fontWeight: "bold",
                              }}
                            >
                              Belum Memiliki Anggota
                            </label>
                          </li>
                        )}
                      </ul>
                    </label>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title
                  as="h3"
                  style={{
                    color: "black",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                >
                  List Cabang
                </Card.Title>
              </Card.Header>
              <Tab.Container>
                <Col>
                  {auth.role === "super admin" || auth.role === "admin" ? (
                    <Button
                      className="btn-wd mr-1"
                      // variant="primary"
                      style={{
                        marginTop: 20,
                        marginLeft: 10,
                        marginBottom: 15,
                        backgroundColor: "#95A3A6",
                        fontWeight: "bold",
                        border: 0,
                      }}
                      onClick={() => setModalCabang(!modalCabang)}
                    >
                      Tambah Cabang
                    </Button>
                  ) : null}
                </Col>
                <Tab.Content>
                  <Card style={{ border: 0 }}>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Col
                        md={"4"}
                        style={{
                          marginTop: 20,
                          marginRight: 25,
                          order: auth.role === "super admin" ? 2 : 1,
                        }}
                      >
                        {auth.role === "super admin" ? (
                          <div>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="Pilih Client"
                              value={filterClient}
                              onChange={(selectedOption) =>
                                handleFilterChange(selectedOption)
                              }
                              options={[
                                { value: "", label: "All" },
                                ...listClient,
                              ]}
                              placeholder="Pilih Client"
                            />
                          </div>
                        ) : null}
                      </Col>

                      <Col
                        md={"4"}
                        style={{
                          marginTop: 20,
                          marginRight: 25,
                          order: 1,
                          marginLeft: 25,
                        }}
                      >
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text>
                              <i className="nc-icon nc-zoom-split icon-bold"></i>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            style={{
                              paddingLeft: "10px",
                            }}
                            type="text"
                            value={searchText}
                            onChange={handleSearch}
                            placeholder="Kode Cabang/Nama Cabang"
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  </Card>
                </Tab.Content>
              </Tab.Container>

              <Tab.Content>
                <Card.Body>
                  <DataTable
                    columns={colCabang}
                    data={listCabang.filter(
                      (row) =>
                        row.cabang_id
                          .toLowerCase()
                          .includes(searchText.toLowerCase()) ||
                        row.namaCabang
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                    )}
                    customStyles={customStyles}
                    pagination
                    paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
                    paginationPerPage={itemsPerPage}
                    onChangePage={handlePageChange}
                  />
                </Card.Body>

                <Modal
                  size="lg"
                  show={modalCabang}
                  onHide={() => {
                    handleModalClose();
                    setModalCabang(false);
                  }}
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Col md="12" style={{ marginTop: 20 }}>
                    <Card className="stacked-form">
                      <Card.Header>
                        <Card.Title
                          as="h4"
                          style={{ color: "black", fontWeight: "bold" }}
                        >
                          Tambah Cabang Baru
                          <hr></hr>
                        </Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <Form action="#" method="#">
                          <Form.Group>
                            <label style={{ color: "black" }}>Id Cabang</label>
                            <Form.Control
                              onChange={(e) => {
                                setIdCabang(e.target.value);
                              }}
                              placeholder="Nama Cabang"
                              type="text"
                            ></Form.Control>
                            <label style={{ color: "black" }}>
                              Nama Cabang
                            </label>
                            <Form.Control
                              onChange={(e) => {
                                setNamaCabang(e.target.value);
                              }}
                              placeholder="Nama Cabang"
                              type="text"
                            ></Form.Control>
                            <label style={{ color: "black" }}>Alamat</label>
                            <Form.Control
                              onChange={(e) => {
                                setAlamat(e.target.value);
                              }}
                              placeholder="Alamat"
                              type="text"
                            ></Form.Control>
                          </Form.Group>
                          {auth.role === "super admin" ? (
                            <Form.Group>
                              <label style={{ color: "black" }}>
                                Pilih Client
                              </label>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="Pilih Client"
                                value={pilihClient}
                                onChange={(value) => {
                                  setPilihClient(value);
                                  setIdClientCbg(value.value);
                                }}
                                options={listClient}
                                placeholder="Pilih Client"
                              />
                            </Form.Group>
                          ) : null}
                        </Form>
                      </Card.Body>
                      <Card.Footer>
                        <Button
                          className="btn-fill"
                          type="submit"
                          variant="info"
                          onClick={submitCabang}
                        >
                          Submit
                        </Button>
                      </Card.Footer>
                    </Card>
                  </Col>
                </Modal>
              </Tab.Content>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* <Card.Title as="h3">List Cabang</Card.Title> */}
    </>
  );
}

export default ListCabang;
